<template>
    <MainLayout>
        <div>
            <SignUp1 @typeUser="typeUser" @nextOnboarding='nextOnboarding' v-if="current_page === 0"/>
            <SetTypeUser @typeUser="typeUser" v-if="!is_type_user && current_page === 1"/>
            <template v-if="is_type_user && current_page > 0">
                <div class="onboarding">
                    <div class="onboarding_header">
                        <div class="image-1">
                            <img class="pt-5" :src="'@/assets/images/'+onboarding[current_page].image" alt=""/>
                        </div>
                        <div class="dots">
                            <img class="" :src="'@/assets/icons/dots'+current_page+'.svg'" alt=""/>
                        </div>
                    </div>
                    <div class="onboarding_footer">
                        <div>
                            <div class="onboarding_title">{{onboarding[current_page].title}}</div>
                            <div class="onboarding_text pt-3">{{onboarding[current_page].text}}</div>
                        </div>
                        <div>
                            <div class="d-flex justify-content-center">
                                <div class="btn-c2" @click="nextOnboarding">Next</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </MainLayout>
</template>

<script>
import SignUp1 from "../components/SignUp1";
import MainLayout from "../layouts/MainLayout";
import SetTypeUser from "../components/SetTypeUser";
import {mapGetters} from "vuex";
import helpFunctions from "../helpFunctions";

export default {
    name: 'Registration',
    components: {
        MainLayout,
        SignUp1,
        SetTypeUser,
    },
    data() {
        return {
            is_type_user: false,
            current_page: 0,
            onboarding: []
        }
    },
    mounted() {
        this.onboarding = helpFunctions.onboading;
        this.isRegistrationSocial();
        this.checkTabQuery();
    },
    methods: {
        nextOnboarding() {
            if(this.current_page === 3) {
                this.$router.push({to: '/', name: "Home"}).then();
            }
            else {
                this.current_page++;
            }
        },
        typeUser(data) {
            this.is_type_user = data;
        },
        isRegistrationSocial() {
            if (localStorage.getItem('strobeart_registration')) {
                this.current_page = 1;
                localStorage.removeItem('strobeart_registration');
            }
        },
        checkTabQuery() {
            let tab = this.$route.query.tab ?? null;
            if (tab == 'type_user') {
                this.current_page = 1;
            }
        }
    },
    computed: {
        ...mapGetters([
            'getUser',
        ]),
    }
}
</script>

<style lang="scss" scoped>
.onboarding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    //padding-bottom: 150px;
    margin-bottom: 200px;
}
.onboarding_header {
    height: 55%;
    display: flex;
    flex-direction: column;
}
.image-1 {
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-1 img {
    background: white;
}
.dots {
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    background: white;
    img {
        background: white;
    }
}
.onboarding_footer {
    height: 45%;
    padding: 44px 51px 59px 51px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.onboarding_title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #494949;
}
.onboarding_text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: #494949;
}

@media only screen and (max-width: 480px) {
    .image-1 img {
        width: 100%;
    }
}
</style>
