<template>
    <div class="item_type_user">
        <div class="type_user">
            <div class="app_logo">
                <img class="" src="@/assets/icons/app_logo.svg" alt="icon-approved.svg"/>
            </div>
            <div class="form_type_user">
                <div class="text_description text-center">Please select account type to finish registration</div>
                <div class="montserrat">
                    <div class="box_input">
                        <div class="mb-4 type_user_select">
                            <select class="form-select" v-model="type_user" aria-label="Default select example">
                                <option selected value="business">Business</option>
                                <option value="editor">Editor</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 box_btn">
                <div class="btn-c2" @click="updateUserType">Finish Sign up</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {errorMessage} from "../services/messages";

export default {
    name: "SetTypeUser",
    data() {
        return {
            type_user: 'business',
        };
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser'
        ]),
        async updateUserType() {
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}update_type_user`, { 'type_user': this.type_user });
                this.setUser(response?.data?.data || {});
                localStorage.setItem('strobeart_user', JSON.stringify(response?.data?.data || {}));
                this.$emit('typeUser', response?.data?.data.type_user || false);
                this.hideLoader();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.text_description {
    padding-bottom: 46px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    color: black;
}
.type_user {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.app_logo {
    display: none;
    padding-top: 80px;
    padding-bottom: 25px;
}
.montserrat .form-control {
    padding: 15px 40px;
}
.item_type_user {
    display: flex;
    justify-content: center;
    margin-top: 179px;
    padding-bottom: 130px;
}
.montserrat {
    width: 100%;
    display: flex;
    justify-content: center;
}
.box_input {
    width: 550px;
}
.box_btn {
    display: grid;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 100px;
}

@media only screen and (max-width: 992px) {
    .item_type_user {
        margin-top: 0px;
        display: inherit;
    }
    .montserrat {
        width: 100%;
    }
    .form_type_user {
        padding: 0 45px 0 45px;
    }
    .app_logo {
        display: block;
    }
    .box_btn {
        padding-top: 10px;
        padding-bottom: 40px;
    }
    .type_user {
        height: 100vh;
    }
}
</style>
